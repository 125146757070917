<template>
  <div>
    <template v-if="!isShowDetail && !isShowAdd">
      <el-row type="flex" justify="space-between">
        <div class="left-item">
          <el-row>
            <el-input v-model="queryForm.searchValue" placeholder="居民姓名/手机号/证件号" class="query-width" size="medium"
              clearable />
            <el-select v-model="queryForm.crowdTypeIds" multiple placeholder="人群分类" class="query-width" size="medium"
              clearable>
              <el-option v-for="item in typepopSelect" :key="item.id" :label="item.ruleName"
                :value="item.id" />
            </el-select>
            <el-select v-model="queryForm.country" placeholder="国籍" class="query-width" size="medium" clearable>
              <el-option v-for="(item, i) in nationalitySelect" :key="i" :label="item.label" :value="item.label" />
            </el-select>
            <el-date-picker style="width: 300px;" v-model="time" size="medium" type="daterange" format="yyyy-MM-dd"
              range-separator="至" start-placeholder="登记开始日期" end-placeholder="登记结束日期" value-format="yyyy-MM-dd" />

          </el-row>
          <div style="margin-top: 20px; height: 40px; line-height: 40px">
            <el-row :gutter="20" type="flex" align="middle">
              <el-col :span="16">
                <el-checkbox-group v-model="queryForm.surveyResults">
                  <!-- <el-checkbox label>全部</el-checkbox> -->
                  <el-checkbox :label="0">排除</el-checkbox>
                  <el-checkbox :label="1">他区协查</el-checkbox>
                  <el-checkbox :label="2">居家隔离</el-checkbox>
                  <el-checkbox :label="3">集中隔离</el-checkbox>
                  <el-checkbox :label="5">院内隔离</el-checkbox>
                  <el-checkbox :label="4">其他</el-checkbox>
                </el-checkbox-group>
              </el-col>
              <el-col :span="4" v-if="queryForm.surveyResults.includes(3)">
                <el-select v-model="queryForm.personStatus" placeholder="状态" class="select-width" size="medium"
                  clearable>
                  <el-option v-for="item in personStatus" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-col>
              <el-checkbox v-model="eventStatus">无事件</el-checkbox>
              <el-cascader style="margin-left:20px" size="medium" placeholder="事件场所" v-model="queryForm.eventsite"
                :options="eventNameSelect" :props="sjprops" filterable collapse-tags clearable></el-cascader>

              <el-select style="margin-left:20px"  v-model="surveyUserName" placeholder="流调人员" class="query-width" clearable>
                <el-option v-for="item in surveySelect" :key="item.dictValue" :label="item.userName" :value="item.userId">
                </el-option>
              </el-select>

            </el-row>
          </div>
        </div>

        <div class="right-item">
          <el-button type="primary" size="medium" @click="queryData('btn')">查询</el-button>
          <el-button type="primary" size="medium" @click="escalateExport('btn')">上报导出汇总</el-button>
          <el-button type="primary" size="medium" @click="escalateExportSelect('btn')">上报导出选中</el-button>
        </div>
      </el-row>

      <div class="table-data">
        <TableModel :is-show-select="true" :loading="loading" :table-data="tableList" :goBeyondHeight="40"
          table-size="medium" :checkData="checkData"  @handleSelectionChange="handleSelection">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column fixed="left" label="操作" width="100">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="handleClick(scope.row)">详情</el-button>
              <el-button type="text" size="small" @click="edit('edit', scope.row)">编辑</el-button>
              <el-button v-if="scope.row.againTransportReg == 1" type="text" size="small" v-btn-permission="'008008002002'" @click="resetTransport(scope.row)">重新转运</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="姓名" width="150" />
          <el-table-column prop="cardType" label="证件类型" width="150">
            <template slot-scope="scope">{{
                scope.row.cardType | dictFilter2(cardTypeDict)
            }}</template>
          </el-table-column>
          <el-table-column prop="cardNo" label="证件号码" width="200" />
          <el-table-column prop="linkPhone" label="联系电话" width="200" />
          <el-table-column prop="crowdTypeName" label="人群分类" width="200" />
          <el-table-column prop="surveyObjectBelongArea" label="所属地区" width="200" />
          <el-table-column prop="taskSource" label="任务来源" show-overflow-tooltip width="200" />
          <el-table-column prop="surveyUserName" label="调查人员" show-overflow-tooltip width="200" />
          <el-table-column prop="surveyDate" label="调查日期" show-overflow-tooltip width="200" />
          <el-table-column prop="eventName" label="事件名称" show-overflow-tooltip width="200" />
          <el-table-column prop="crtTime" label="流调登记时间" show-overflow-tooltip width="200" />
          <el-table-column prop="startTime" label="开始隔离时间" show-overflow-tooltip width="200" />
          <el-table-column prop="transferTime" label="解除隔离时间" show-overflow-tooltip width="200" />
          <el-table-column prop="surveyResultStatus" label="状态" show-overflow-tooltip width="200">
            <!-- <template slot-scope="scope">{{ scope.row.personStatus | personStatusFilter }}</template> -->
          </el-table-column>
          <el-table-column prop="transferType" label="转出类型" show-overflow-tooltip width="200">
            <template slot-scope="scope">{{
                scope.row.transferType | transferTypeFilter
            }}</template>
          </el-table-column>
        </TableModel>

        <div class="content_page">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="pageNumber" :total="total" :page-sizes="[20, 30, 50, 100]" :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper" />
        </div>
      </div>
    </template>

    <!-- 居民详情 -->
    <div v-if="isShowDetail">
      <residentInformation ref="residentInformationRef" />
    </div>
    <template v-if="isShowAdd">
      <!-- <add-epidemiological-survey ref="addEpidemiologicalSurvey" :type="type" :id="id"></add-epidemiological-survey> -->
      <AddFlowTone v-if="isShowAdd" ref="addEpidemiologicalSurvey" :type="type" :id="id" :rowdata="rowdata">
      </AddFlowTone>
    </template>
  </div>
</template>

<script>
import { Loading } from "element-ui";
import { http } from "@/api/index";

import TableModel from "@/components/TableModel";
import residentInformation from "@/views/QuarantineRegistration/residentInformation";
import { getCrowdTypeList } from "@/api/RuleManagement/crowdAnalysis";
import {
  getEpidemiologicalSurveyListApi,
  exportEpidemiologicalSurveyList,
  getEventListApi,
  getSurveyUserList,
  initTransportApi,
} from "@/api/DiseaseControlManagement/DiseaseControlManagement";
import { mapState } from "vuex";
import constants from "@/common/constants";
import { PERSON_STATUS } from "@/common/constants";
import AddEpidemiologicalSurvey from "../EpidemiologicalSurveyRegister/AddEpidemiologicalSurvey.vue";
import AddFlowTone from "../EpidemiologicalSurveyRegister/AddFlowTone.vue";
import { importFile, messageBox } from "@/utils/utils";
import moment from 'moment';

export default {
  components: {
    TableModel,
    residentInformation,
    AddEpidemiologicalSurvey,
    AddFlowTone,
  },
  data() {
    return {
      loading: false,
      isShowDetail: false,
      tableList: [],
      eventStatus: false,
      newArr: [],
      input: "",
      eventNameSelect: [],
      time: [moment().format("YYYY-MM-DD"),moment().format("YYYY-MM-DD")],
      value: "",
      option: [],
      queryForm: {
        searchValue: "",
        crowdTypeIds: [],
        country: "",
        surveyResults: [],
        personStatus: "",
        eventsite: '',
        eventStatus: 0
      },
      pageNumber: 1,
      rowdata: "",
      pageSize: 20,
      total: 0,
      typepopSelect: [],
      checkData: [], //选中的数组
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      roles: JSON.parse(localStorage.getItem("roles")),
      isShowAdd: false, // 是否显示编辑
      type: "", //edit 编辑
      id: "", //编辑记录id
      // 场所事件
      surveySelect: [],
      surveyUserName: "",
      sjprops: {
        value: "id",
        label: "eventName",
        lazy: true,
        multiple: true,
        checkStrictly: true,
        lazyLoad(node, resolve) {
          const { level } = node;

          // 获取调查场所
          let params = { eventId: node.value };
          if (level == 1) {
            http({
              method: "get",
              url: `/ohealth/api/v1/epidemicControl/placeinvolved/getPlaceList?eventId=${node.value}`,
              data: params,
            }).then(function (response) {
              let list = response.data.data;

              const nodes = list.map((item) => ({
                id: item.id,
                eventName: item.placeName,
                leaf: level >= 1,
              }));
              resolve(nodes);
            });
          }
        },
      },
    };
  },
  computed: {
    ...mapState({
      isolationList: (state) => state.user.isolationList, // 隔离点列表数据
      nationalitySelect: (state) => state.user.nationalitySelect, // 国籍
    }),
  },
  watch: {
    isShowAdd(newVal) {
      if (!newVal) {
        this.getTableList();
      }
    },
  },
  created() {
    this.cardTypeDict = constants.TYPE_CERTIFICATE;
    this.personStatus = PERSON_STATUS;
    this.getTableList();
    this.getCrowdTypeList();
    this.getEventList();
    this.getSurveyUserList(); //流调人员

  },
  filters: {
    personStatusFilter: function (value) {
      if (!value) return "";
      const personStatus = PERSON_STATUS.find((item) => {
        return item.value == value;
      });
      return personStatus ? personStatus.label : "";
    },
    transferTypeFilter: function (value) {
      if (!value) return "";
      const transferType = constants.TRANSFER_TYPE_SELECT.find((item) => {
        return item.value == value;
      });
      return transferType ? transferType.label : "";
    },
  },
  methods: {
    //重新转运
    // 单条
    resetTransport(row){
      // console.log(row.againTransportReg);
      // console.log('重新转运');
      let params = [row.id];
      this.transport(params);
    },
    async transport(params) {
      if(this.checkData.length == 0){
        this.$message({
                type: 'warning',
                message: '此操作至少勾选1条数据!'
        });
        return
      }
      if(this.checkData.length != 1){
        this.$message({
          message: '此处为单个操作，如需批量操作请点击批量操作按钮！',
          type: 'warning'
        });
      }else{
        this.$confirm('确定要将改人员状态回到待转运吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let that = this
          this.loading = true
          // console.log(this.loading);
          initTransportApi(params).then(res=>{
            if(res.code == 200){
              
              this.checkData = []
              this.$message({
                type: 'success',
                message: '转运成功!'
              });
            }else{
              this.$message.error('转运失败！');
            }
            that.queryData()
            this.loading = false
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });          
        });
      }
        
      
    },
    //接收表格的选中项
    handleSelection(val) {
      this.checkData = val;
    },
    // 获取流调人员
    async getSurveyUserList() {
      let params = {};
      let { data } = await getSurveyUserList({ params });
      if (data.code == 200) {
        this.surveySelect = data.data;
      }
    },
    // 设置请求参数
    setParamsInfo() {

      let eventIds = [],
        placeIds = "",
        checkds = [],
        eventPlaceIds = []
      let arrrrrrrr = this.queryForm.eventsite;

      for (let i in arrrrrrrr) {
        // arrrrrrrr[i]
        if (arrrrrrrr[i][1]) {
          eventIds = `${arrrrrrrr[i][0]},${arrrrrrrr[i][1]}`
          eventPlaceIds.push(eventIds)
          checkds.push(arrrrrrrr[i][0]);
        } else if (arrrrrrrr[i][0] != null) {
          eventPlaceIds.push(arrrrrrrr[i][0])
        }

      }

      let newArr = eventPlaceIds.filter((item) => !checkds.some((ele) => ele === item)); //对比两个数组，把有相同的删掉
      let params = {
        hospitalId: this.userdoctor.hospitalId,
        orgCode: this.userdoctor.orgCode,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        eventPlaceIds: newArr


      };
      // console.log("🚀 ~ file: EpidemiologicalSurveyList.vue ~ line 29 ~ setParamsInfo ~ params", params)

      let { searchValue, country, crowdTypeIds, surveyResults, personStatus } =
        this.queryForm;
      if (searchValue) params.searchValue = searchValue;
      if (country) params.country = country;
      if (crowdTypeIds) params.crowdTypeIds = crowdTypeIds;
      if (personStatus) params.personStatus = personStatus;
      params.surveyResults = surveyResults;

      if (this.time && this.time.length == 2) {
        params.startTime = this.time[0] + " 00:00:00";
        params.endTime = this.time[1] + " 23:59:59";
      }

      // console.log("🚀 ~ file: EpidemiologicalSurveyList.vue ~ line 279 ~ setParamsInfo ~ params", params)

      return params;
    },

    // 查询流调列表
    async getTableList(val) {
      if (val == "btn") {
        this.pageNumber = 1;
      }
      this.loading = true;
      if (
        this.eventStatus == true
      ) {
        this.queryForm.eventStatus = 1
      } else {
        this.queryForm.eventStatus = 0

      }
      // let params = this.setParamsInfo();

      let eventIds = [],
        placeIds = "",
        checkds = [],
        eventPlaceIds = []
      let arrrrrrrr = this.queryForm.eventsite;

      for (let i in arrrrrrrr) {
        // arrrrrrrr[i]
        if (arrrrrrrr[i][1]) {
          eventIds = `${arrrrrrrr[i][0]},${arrrrrrrr[i][1]}`
          eventPlaceIds.push(eventIds)
          checkds.push(arrrrrrrr[i][0]);
        } else if (arrrrrrrr[i][0] != null) {
          eventPlaceIds.push(arrrrrrrr[i][0])
        }

      }

      let newArr = eventPlaceIds.filter((item) => !checkds.some((ele) => ele === item)); //对比两个数组，把有相同的删掉
      let params = {
        hospitalId: this.userdoctor.hospitalId,
        orgCode: this.userdoctor.orgCode,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        eventPlaceIds: newArr,
        searchValue: this.queryForm.searchValue,
        country: this.queryForm.country,
        crowdTypeIds: this.queryForm.crowdTypeIds,
        personStatus: this.queryForm.personStatus,
        surveyResults: this.queryForm.surveyResults,
        eventStatus: this.queryForm.eventStatus,
        surveyUserId: this.surveyUserName,

      };
      if (this.time && this.time.length == 2) {
        params.startTime = this.time[0] + " 00:00:00";
        params.endTime = this.time[1] + " 23:59:59";
      }
      console.log(params);
      const result = await getEpidemiologicalSurveyListApi(params);
      let { code, data, msg } = result.data;
      if (code == 200) {
        let { totalRow, list } = data;
        this.tableList = list;
        this.total = totalRow;
      } else {
        this.$message.error(msg);
      }
      setTimeout(() => {
        this.loading = false;
      }, 100);
    },

    // 查看更多
    handleClick(row) {
      this.isShowDetail = true;
      this.$nextTick(() => {
        this.$refs.residentInformationRef.getSurveyPersonInfo(row);
      });
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableList();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.getTableList();
    },

    queryData(val) {
      this.getTableList(val);
    },
    //编辑
    edit(type, row) {
      this.isShowAdd = true;
      this.type = type;
      this.id = row.id;
    },
    // 人群分类
    async getCrowdTypeList() {
      const { data } = await getCrowdTypeList();
      if (data.code == "200") {
        this.typepopSelect = data.data;
      }
    },
    //获取事件列表
    async getEventList(val) {
      const params = {};
      const { data } = await getEventListApi({ params });
      if (data.code == "200") {
        this.eventNameSelect = data.data;
      }
    },
    // 导出
    escalateExport() {
      let timee;
      if (
        this.eventStatus == true
      ) {
        this.queryForm.eventStatus = 1
      } else {
        this.queryForm.eventStatus = 0

      }
      // let params = Object.assign(
      //   {
      //     crowdType: this.crowdType,
      //     hospitalId: this.userdoctor.hospitalId,
      //     orgCode: this.userdoctor.orgCode,
      //     ...this.queryForm,
      //   }

      //   // this.tableList
      // );
      // if (this.time && this.time.length == 2) {
      //   params.startTime = this.time[0] + " 00:00:00";
      //   params.endTime = this.time[1] + " 23:59:59";
      // }

      let eventIds = [],
        placeIds = "",
        checkds = [],
        eventPlaceIds = []
      let arrrrrrrr = this.queryForm.eventsite;

      for (let i in arrrrrrrr) {
        // arrrrrrrr[i]
        if (arrrrrrrr[i][1]) {
          eventIds = `${arrrrrrrr[i][0]},${arrrrrrrr[i][1]}`
          eventPlaceIds.push(eventIds)
          checkds.push(arrrrrrrr[i][0]);
        } else if (arrrrrrrr[i][0] != null) {
          eventPlaceIds.push(arrrrrrrr[i][0])
        }

      }

      let newArr = eventPlaceIds.filter((item) => !checkds.some((ele) => ele === item)); //对比两个数组，把有相同的删掉
      let params = {
        hospitalId: this.userdoctor.hospitalId,
        orgCode: this.userdoctor.orgCode,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        eventPlaceIds: newArr,
        searchValue: this.queryForm.searchValue,
        country: this.queryForm.country,
        crowdTypeIds: this.queryForm.crowdTypeIds,
        personStatus: this.queryForm.personStatus,
        surveyResults: this.queryForm.surveyResults,
        eventStatus: this.queryForm.eventStatus,
        surveyUserId: this.surveyUserName



      };
      if (this.time && this.time.length == 2) {
        params.startTime = this.time[0] + " 00:00:00";
        params.endTime = this.time[1] + " 23:59:59";
      }

      messageBox(
        () => {
          let loadingInstance = Loading.service();
          Loading.service();
          this.fullscreenLoading = true;
          exportEpidemiologicalSurveyList(params).then((res) => {
            // console.log(11111111111, res);
            this.fullscreenLoading = false;
            setTimeout(() => {
              loadingInstance.close();
            }, 1000);
            importFile(res.data, "疫情排查检测管控统计");
          });
        },
        "warning",
        "是否确认导出汇总 疾控列表 数据？"
      );
    },
  // 导出
  escalateExportSelect() {
    let timee;
    if (
        this.eventStatus == true
    ) {
      this.queryForm.eventStatus = 1
    } else {
      this.queryForm.eventStatus = 0

    }
    // let params = Object.assign(
    //   {
    //     crowdType: this.crowdType,
    //     hospitalId: this.userdoctor.hospitalId,
    //     orgCode: this.userdoctor.orgCode,
    //     ...this.queryForm,
    //   }

    //   // this.tableList
    // );
    // if (this.time && this.time.length == 2) {
    //   params.startTime = this.time[0] + " 00:00:00";
    //   params.endTime = this.time[1] + " 23:59:59";
    // }

    let eventIds = [],
        placeIds = "",
        checkds = [],
        eventPlaceIds = []
    let arrrrrrrr = this.queryForm.eventsite;

    for (let i in arrrrrrrr) {
      // arrrrrrrr[i]
      if (arrrrrrrr[i][1]) {
        eventIds = `${arrrrrrrr[i][0]},${arrrrrrrr[i][1]}`
        eventPlaceIds.push(eventIds)
        checkds.push(arrrrrrrr[i][0]);
      } else if (arrrrrrrr[i][0] != null) {
        eventPlaceIds.push(arrrrrrrr[i][0])
      }

    }

    let newArr = eventPlaceIds.filter((item) => !checkds.some((ele) => ele === item)); //对比两个数组，把有相同的删掉
    let params = {
      ids: this.checkData,
      hospitalId: this.userdoctor.hospitalId,
      orgCode: this.userdoctor.orgCode,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      eventPlaceIds: newArr,
      searchValue: this.queryForm.searchValue,
      country: this.queryForm.country,
      crowdTypeIds: this.queryForm.crowdTypeIds,
      personStatus: this.queryForm.personStatus,
      surveyResults: this.queryForm.surveyResults,
      eventStatus: this.queryForm.eventStatus,
      surveyUserId: this.surveyUserName
    };
    if (this.time && this.time.length == 2) {
      params.startTime = this.time[0] + " 00:00:00";
      params.endTime = this.time[1] + " 23:59:59";
    }

    messageBox(
        () => {
          let loadingInstance = Loading.service();
          Loading.service();
          this.fullscreenLoading = true;
          exportEpidemiologicalSurveyList(params).then((res) => {
            // console.log(11111111111, res);
            this.fullscreenLoading = false;
            setTimeout(() => {
              loadingInstance.close();
            }, 1000);
            importFile(res.data, "疫情排查检测管控统计");
          });
        },
        "warning",
        "是否确认导出选中 疾控列表 数据？"
    );
  },
},
};
</script>

<style lang="scss" scoped>
@import "../../../static/css/condition-filtrate.scss";

.query-width {
  width: 220px;
  margin-right: 20px;
}

.table-data {
  margin-top: 20px;
}

.content_page {
  margin-top: 10px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
}
</style>