<template>
  <div> 
    <div class="title">
      <span class="blue"></span>
      <p style="font-size: 16px">关联阳性病例信息</p>
      <el-button type="primary" class="add-btn" size="small" @click="addPostiveCase">添加</el-button>
    </div>
    <el-tabs v-model="editableTabsValue" type="card" closable @tab-remove="removeTab">
      <el-tab-pane :key="item.name" v-for="item in editableTabs" :label="item.title" :name="item.name">
        <positive-case-form :gsoncrowdType="gsoncrowdType" :ref="`positiveCaseFormRef${item.data.sort}`"
          :sort="item.data.sort" :formData="item.data" @onenmb="onenmb">
        </positive-case-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import PositiveCaseForm from "./PositiveCaseForm.vue";
export default {
  props: ["crowdType","addtype"],
  name: "RelatePositiveCase",
  data() {
    return {
      positiveCaseInfo: [],
      gsoncrowdType: "",
      editableTabsValue: "0",
      editableTabs: [],
      tabIndex: 0,
    };
  },
  components: { PositiveCaseForm },
  created() { },
  mounted() { 

  },
  methods: {
    //渲染数据
    renderData() {
      this.editableTabs.forEach((item) => {
        this.$ref[`positiveCaseFormRef${item.data.sort}`].positiveCaseForm =
          item.data;
      });
    },
    // 提交校验
    comm() {
      return this.editableTabs.map((item) => {
        return this.$refs[`positiveCaseFormRef${item.data.sort}`][0].commit();
      });
    },
    //提交数据
    commData() {
      let wfnlbugg = [];

      this.editableTabs.forEach((item) => {
        let data =
          this.$refs[`positiveCaseFormRef${item.data.sort}`][0]
            .positiveCaseForm;
        const index = this.positiveCaseInfo.findIndex((item) => {
          return item.sort == data.sort;
        });
        let sorrtttt = JSON.parse(JSON.stringify(item.data.sort));

        data.sort = sorrtttt;
        sessionStorage.setItem(`wcaa${sorrtttt}`, JSON.stringify(data));
        let aaa = JSON.parse(sessionStorage.getItem(`wcaa${sorrtttt}`)); //取
        wfnlbugg.push(aaa);

        this.positiveCaseInfo.splice(index, 1, data);
      });
      for (let i in this.positiveCaseInfo) {
        if (this.positiveCaseInfo[i].sort == wfnlbugg[i].sort) {
          this.positiveCaseInfo[i] = wfnlbugg[i];
        }
      }
    },

    // 重置
    resetf() {
      this.editableTabs.forEach((item) => {
        this.$refs[`positiveCaseFormRef${item.data.sort}`][0].reset();
      });
      this.editableTabs = [];
    },
    //新增阳性
    addPostiveCase() {
      console.log("🚀 ~ 阳性次密状态this.crowdType", this.crowdType);
      
      console.log("🚀 ~ 这个this.addtype", this.addtype);
      

      this.gsoncrowdType = this.crowdType;

      this.$forceUpdate();
      let newTabName = ++this.tabIndex + "";
      let data = {
        type: 0,
        sort: this.tabIndex,
        relationName: "",
        relationCardType: "1",
        relationCardNo: "",
        relationAddress: "",
        relationAddressCode: "",
        probabilityRelation: false,
        dateOfOnset: "",
        receivingOrg: "",
        relationType: "",
        otherRelation: "",
        firstContactTime: "",
        lastContactTime: "",
        contactFrequencyType: "",
        contactAddress: "",
        contactType: "",
        vehicle: "",
        singleExposureTime: "",
        // isPR: true,
        gsoncrowdType: this.gsoncrowdType,
        addtype:this.addtype
      };
     
      if(this.crowdType != ''&&this.addtype == 'add'&&this.crowdType != '7'&& !this.editableTabs.length){
          data.probabilityRelation = true;
        }
      
      if(this.crowdType != ''&&this.addtype =='edit'&&this.crowdType != '7'&& !this.editableTabs.length){
        data.probabilityRelation = true;
      }
        console.log(data.probabilityRelation,'打印data.probabilityRelation')
      this.positiveCaseInfo.push(data);
      this.editableTabs.push({
        title: `关联${this.editableTabs.length+1}`,
        name: newTabName,
        data: data,
      });
      this.editableTabsValue = newTabName;
      this.isaa = true;
    },
    //删除关联者
    removeTab(targetName, action) {
      let tabs = this.editableTabs;
      let activeName = this.editableTabsValue;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }
      this.editableTabsValue = activeName;
      //删除tab
      let tabIndex = tabs.findIndex((item) => {
        return item.name == targetName;
      });
      this.editableTabs.splice(tabIndex, 1);
      //删除positiveCaseInfo
      let positiveCaseIndex = this.positiveCaseInfo.findIndex((item) => {
        return item.sort == targetName;
      });
      this.positiveCaseInfo.splice(positiveCaseIndex, 1);
    },
    
    // 可能性最大的关联者只能勾选一个
    onenmb() {
      // 2 清空所有的值
      for (let i of this.editableTabs) {
        this.$refs[
          `positiveCaseFormRef${i.data.sort}`
        ][0].positiveCaseForm.probabilityRelation = false;
      }

      this.$emit("father102", "1234");
    },
    // 防溢出
    onenmbfyc() {
      for (let i of this.editableTabs) {
        this.$refs[
          `positiveCaseFormRef${i.data.sort}`
        ][0].positiveCaseForm.probabilityRelation = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.blue {
  width: 4px;
  height: 20px;
  background-color: #3476f5;
  margin-right: 6px;
}

.add-btn {
  margin-left: 20px;
}

.select-width {
  width: 80%;
}

.content-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formitem {
  width: 50%;
}

.special {
  flex: 1;
}

.relation-width {
  display: inline;
  width: 50%;
}
</style>